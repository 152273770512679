.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login-form {
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(56, 71, 109, 0.03);
  border-radius: 12px;
  padding: 50px;
}
.login-form-header {
  text-align: center;
}
.login-form-header h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(53, 58, 64, 0.8);
  margin: 10px 0px;
}
.text-red {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #f43c3c;
  font-family: "DM Sans";
}
.rember {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(53, 58, 64, 0.6);
}
.login-form-body .form-control {
  height: 50px;
  background: #f4f8fb !important;
  border: 1px solid rgba(216, 218, 225, 0.7) !important;
  border-radius: 5px;
}
.forgot-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(53, 58, 64, 0.6);
}

.login-form-body .form-control::-webkit-input-placeholder {
  color: rgba(34, 41, 67, 0.6);
  font-weight: 500;
  font-size: 16px;
}
.login-form-body .form-control::-moz-placeholder {
  color: rgba(34, 41, 67, 0.6);
  font-weight: 500;
  font-size: 16px;
} /* firefox 19+ */
.login-form-body .form-control:-ms-input-placeholder {
  color: rgba(34, 41, 67, 0.6);
  font-weight: 500;
  font-size: 16px;
} /* ie */
.login-form-body .form-control:-moz-placeholder {
  color: rgba(34, 41, 67, 0.6);
  font-weight: 500;
  font-size: 16px;
}

.login-form-body .form-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(34, 41, 67, 0.8);
}
